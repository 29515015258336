<template>
  <div>
    <div class="project-container">
		<project v-for="project of hardwareProjects" :key="project.title" :project="project" class="project"></project>
	</div>
  </div>
</template>
<script>
export const HARDWARE_PROJECTS = [
	{
		title: "Digital Fabrication",
		link: "https://emeersman.github.io/hcde598/",
		description: "Website showcasing projects from a hands-on course in digital fabrication. I designed and built projects using many different materials, software programs, and machines."
	},
	{
		title: "MQTT Weather Station",
		link: "https://github.com/emeersman/design-for-iot/tree/master/A3_WeatherStation",
		description: "Weather station using sensors to track local environment conditions and publish them to an MQTT feed brokered on a Raspberry Pi."
	},
	// {
	// 	title: "Raspberry Pi Remote Light Switch",
	// 	link: "",
	// 	description: "Set up Rasberry Pi as an MQTT broker and wired a button to publish to a feed and toggle the lights when pressed."
	// },
	{
		title: "Arduino Night Light",
		link: "https://github.com/emeersman/cse590/tree/master/NightLight",
		description: "Pond-themed night light using a variety of hardware sensors across multiple modes."
	},
	{
		title: "Step Tracker",
		link: "https://github.com/emeersman/cse590/tree/master/StepTracker",
		description: "Accelerometer-driven step tracker as well as progress visualizations toward a daily step goal."
	},
	// {
	// 	title: "Personal makerspace projects",
	// 	description: "TODO OMG (Coasters at least, maybe ornaments? lake map? catio sign?)"
	// }
]

import Project from '@/components/Project.vue'

export default {
	name: 'Hardware',
	components: {
		Project
	},
	data () {
		return {
			hardwareProjects: HARDWARE_PROJECTS
		}
	}
}
</script>
<style scoped>
.project-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}
</style>
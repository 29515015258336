<template>
  <div class="project">
    <a :href="project.link">
      <h3>{{project.title}}</h3>
      <p>{{project.description}}</p>
    </a>
  </div>
</template>
<script>
export default {
  name: 'Project',
  props: {
    project: null
  }
}
</script>
<style>
.project {
  border-radius: 8px;
  background-color: rgba(4, 99, 7, 0.8);
  margin: 20px;
  box-shadow: 5px 5px 10px #424242;
  backdrop-filter: blur(5px);
}

.project:hover {
  background-color: rgba(4, 99, 7, 1);
  box-shadow: 5px 5px 6px #424242;
}

h3 {
  padding: 8px 24px 0px;
}

a {
  color: #ffffff;
  text-decoration: none;
}

p {
  padding: 8px 24px;
}
</style>
